<template>
  <div class="team block">
    <div class="container"  id="command">
      <section class="section__left">
        <h3>
          {{$t('team.title')}}
        </h3>
        <h3 class="team__title">
          {{$t('team.subtitle')}}
        </h3>
        <div class="team__items">
          <div class="team__item" v-for="item in 5" :key="item">
            <Icon name="check"></Icon>
            <div>
              {{$t('team.items.'+item)}}
            </div>
          </div>
        </div>
      </section>
      <section class="team__img"/>
    </div>
  </div>
</template>
<style lang="scss" scoped>
  .team {
    background: $dark;
    color: $white;

    h3 {
      color: $white;
      margin-bottom: 50px;
      padding-right: 20px;

      &.team__title {
        margin-bottom: 25px;
      }
    }

    .container {
      flex-direction: row;
      align-items: center;


      @media (max-width: $mobile-point) {
        flex-direction: column-reverse;
      }
    }

    &__item {
      display: flex;
      align-items: center;
      margin-bottom: 15px;

      .svg-icon {
        margin-right: 15px;

        height: 24px;
        max-width: 24px;
        min-width: 24px;
      }
    }

    section {
      flex: 1;
    }

    .section__left {
      padding-right: 10%;
    }

    &__img {
      background-image: url("/photos/office/DSCF0623-min.jpg");
      background-size: cover;
      background-position: center;
      border-radius: 15px;
      background-color: $gray;
      min-height: 518px;
      min-width: 50%;

      @media (max-width: $mobile-point) {
        min-height: 300px;
        width: 100%;
        margin-bottom: 25px;
      }
    }
  }
</style>