<template>
    <div class="menu">
        <Icon @click="open" name="menu"/>
      <transition name="slide-fade">
        <div class="menu-content" @click.self="close" v-if="isOpen"  v-body-scroll-lock="isOpen">
            <div class="menu-nav">
                <div class="menu-close" @click="close">
                    <Icon name="close"></Icon>
                </div>
                <Nav/>
              <Social/>
            </div>
        </div>
      </transition>
    </div>
</template>

<script>
  import Nav from '@/components/Nav.vue';
  import Social from '@/components/Social.vue';

    export default {
        data() {
            return {
                isOpen: false
            }
        },
        methods: {
            close() {
                this.isOpen = false;
            },
            redirect(route) {
                this.close()
                this.$router.push({name: route})
            },
            open() {
                this.isOpen = true;
            }
        },
      components: {
        Nav,
        Social
      },
      watch: {
        $route() {
          this.close()
        }
      }
    }
</script>

<style lang="scss">
    .menu {
        display: none;

        @media (max-width: $tablet-point) {
            display: flex;
        }

        &-content {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            background: #F1F3F6;
            height: 100%;
            z-index: 100;
            display: flex;
            overscroll-behavior: contain;
        }

        &-nav {
            display: flex;
            flex-direction: column;
            width: fit-content;
            margin: 0 auto;
            justify-content: center;

            a, span{
              font-style: normal;
              font-weight: 700;
              font-size: 36px;
              line-height: 125%;
              color: #2E3948;
              margin-bottom: 25px;
              text-align: center;
            }

            .social {
              display: flex;
              justify-content: center;

              .svg-icon {
                @media (max-width: $mobile-point) {
                  height: 32px;
                  max-width: 32px;
                  min-width: 32px;
                }
              }
            }
        }

        &-close {
            position: absolute;
            right: 20px;
            top: 27px;
        }
    }
</style>