<template>
  <form action="">
    <input class="input" v-model="candidate.name"
           :class="{'input-error--border': errors.name}"
           type="text" :placeholder="$t('form.name')">
    <div class="input-error" v-if="errors.name">{{$t('form.error.name')}}</div>
    <input class="input" :class="{'input-error--border': errors.email}"
           v-model="candidate.email" type="text" placeholder="Email">
    <div class="input-error" v-if="errors.email">{{$t('form.error.email')}}</div>
    <textarea class="textarea" :class="{'input-error--border': errors.text}"
              v-model="candidate.text" rows="5" :placeholder="$t('form.comment')"/>
    <div class="input-error" v-if="errors.text">{{$t('form.error.text')}}</div>
    <button type="button" @click="sendForm" class="btn">
      <div class="btn-loading" v-if="isLoading"></div>
      <span v-else>{{ $t("form.send") }}</span>
    </button>
  </form>
</template>
<script>
  import { sendContact } from "@/api";

  export default {
    data() {
      return {
        candidate: {
          name:'',
          email: '',
          text: '',
        },
        errors: {},
        isLoading: false
      }
    },
    methods: {
      clearErrors() {
        this.errors = {}
      },
      async sendForm() {
        this.clearErrors();

        if (!this.candidate.name) {
          this.errors.name = this.$t('form.error.name')
        }

        if (!this.candidate.text || this.candidate.text.length < 10) {
          this.errors.text = this.$t('form.error.text')
        }

        const mailformat = /\S+@\S+\.\S+/;

        if (!this.candidate.email || !this.candidate.email.match(mailformat)) {
          this.errors.email = this.$t('form.error.email')
        }

        if (this.isLoading || this.errors.name || this.errors.email || this.errors.text) {
          return
        }

        this.isLoading = true;

        let captcha = await this.$grecaptchaExecute();

        try {
          await sendContact({...this.candidate, captcha});

          this.$notify({
            group: 'foo',
            title: this.$t('form.success'),
          });

          this.clearErrors();
          this.candidate.email = '';
          this.candidate.name = '';
          this.candidate.text = '';

        } catch (e) {
          // eslint-disable-next-line no-console
          console.error(e);

          this.$notify({
            group: 'foo',
            title: this.$t('form.error.title'),
          });
        } finally {
          this.isLoading = false;
        }

      },
    }
  }
</script>
<style lang="scss" scoped>
  form {
    width: 100%;

    .input, .textarea {
      margin-bottom: 15px;
    }
  }
</style>