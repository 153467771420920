<template>
  <div>
    <div class="container">
      <h2>
        {{$t('employee.title')}}
      </h2>
    </div>
    <div class="employee__items">
      <swiper
        :slides-per-view="'auto'"
        @init="onInit"
        :loop="true"
        :pagination="{type: 'progressbar', el: '.swiper__pagination--2'}"
        :space-between="20">
        <swiper-slide v-for="item in items" :key="item">
            <div class="flip-card">
              <div class="flip-card-inner">
                <div class="flip-card-front" :style="{ 'background-image': 'url(/employee/' + item + '.jpg)' }">
                  <div class="employee__title">
                    <h3>{{$t('employee.items.'+item+'.name')}}</h3>
                    <p>{{$t('employee.items.'+item+'.position')}}</p>
                  </div>
                </div>
                <div class="flip-card-back">
                  <div class="employee__description">
                    {{$t('employee.items.'+item+'.post')}}
                  </div>
                  <div class="employee__title">
                    <div class="employee__avatar" :style="{ 'background-image': 'url(/employee/' + item + '.jpg)' }"></div>
                    <div>
                      <h3>{{$t('employee.items.'+item+'.name')}}</h3>
                      <p>{{$t('employee.items.'+item+'.position')}}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </swiper-slide>
      </swiper>
    </div>
    <div class="container container--column">
      <div class="swiper__bottom">
        <div class="swiper__navigation">
          <div class="swiper__prev" @click="swiper.slidePrev()">
            <Icon name="arrow"></Icon>
          </div>
          <div class="swiper__next" @click="swiper.slideNext()">
            <Icon name="arrow"></Icon>
          </div>
        </div>
        <div class="swiper__progress">
          <div class="swiper__pagination--2"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { Swiper, SwiperSlide } from 'swiper/vue';

  import SwiperCore, { Pagination } from 'swiper';
  SwiperCore.use([Pagination]);

  export default {
    data() {
      return {
        items: ['dima','qa','lena', 'hr','serg', 'ui']
      }
    },
    methods: {
      onInit(swiper) {
        this.swiper = swiper
      }
    },
    components: {
      Swiper,
      SwiperSlide
    }
  }
</script>
<style lang="scss">
  .employee {

    &__items {
      margin-bottom: 50px;
      display: flex;
      
      @media (max-width: $tablet-point) {
        margin-bottom: 25px;
        padding: 0 15px;
      }
    }

    &__item {
      display: flex;
      width: 100%;

      img {
        width: 100%;
      }
    }

    &__title {
      position: absolute;
      bottom: 0;
      padding: 50px;
      width: 100%;
      text-align: left;
      left: 0;

      h3 {
        color: $white;
        margin-bottom: 5px;
      }

      p {
        color: $accent;
      }
    }

    &__description {
      text-align: left;
      flex: 1;
    }

    &__avatar {
      min-width: 50px;
      max-width: 50px;
      height: 50px;
      border-radius: 50%;
      margin-right: 10px;
      background-size: cover;
    }
  }

  .flip-card {
    background-color: transparent;
    width: 387px;
    height: 552px;
    perspective: 1000px;
  }

  .flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.6s;
    transform-style: preserve-3d;
  }



  .flip-card-front, .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    background-size: cover;
    border-radius: 15px;
    background-color: $gray;
  }

  .flip-card-back {
    background-color: $gray;
    color: $dark;
    transform: rotateY(0);
    padding: 50px;
    display: flex;
    flex-direction: column;

    .employee__title {
      display: flex;
      align-items: center;
      padding: 0;
      width: 100%;
      position: relative;

      h3 {
        color: $dark;
      }
      p {
        color: $gray-text;
      }
    }
  }

  .no-touch {
    .flip-card {
      &-back {
        transform: rotateY(180deg);
        background-color: $dark;
        color: $white;

        .employee__title {
          h3 {
            color: $white;
          }
          p {
            color: $accent;
          }
        }
      }

      &:hover {
        .flip-card-inner {
            transform: rotateY(180deg);
        }
      }
    }
  }
</style>