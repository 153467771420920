<template>
  <div class="block">
    <div class="container container--column" id="career">
        <h2>{{$t('career.title')}}</h2>
        <div class="career__items">
          <div class="career__item" v-for="item in items" :key="item">
              <Icon :name="item"></Icon>{{$t("career.items."+item)}}
          </div>
        </div>
    </div>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        items: ['book', 'discussion', 'team-career', 'comfort', 'money', 'car']
      }
    }
  }
</script>
<style lang="scss">
  .career {
    &__items {
      display: grid;
      grid-template-columns: repeat(3,1fr);
      grid-gap: 20px;

      @media (max-width:  $tablet-point) {
        grid-template-columns: repeat(2, 1fr);
      }

      @media (max-width: $mobile-point) {
        grid-template-columns: 1fr;
      }
    }

    &__item {
      height: 125px;
      display: flex;
      align-items: center;
      border-radius: 15px;
      border: 1px solid $gray;
      padding: 25px;


      .svg-icon {
        padding: 10px;
        background: $gray;
        border-radius: 10px;
        margin-right: 25px;
      }

    }
  }
</style>