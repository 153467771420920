<template>
  <div class="benefits block">
    <div class="container container--column">
      <h2>{{$t("benefits.title")}}</h2>
        <p>{{$t("benefits.description")}}</p>
      <div class="benefits__items">
          <div class="benefits__item" v-for="item in items" :key="item">
              <h3>
                  <Icon :name="item"></Icon>{{$t("benefits.items."+item)}}
              </h3>
              <p>
                  {{$t("benefits.desc."+item)}}
              </p>
          </div>

      </div>
    </div>
  </div>
</template>
<script>
  import Icon from '@/components/UI/SvgIcon.vue';

  export default {
    components: {
      Icon
    },
    data() {
      return {
        items: ['team', 'result', 'documents', 'innovations']
      }
    }
  }
</script>
<style lang="scss">
  .benefits {
    background: $gray;

    h2 {
        margin-bottom: 25px;
    }

    p {
      max-width: 50%;

      @media (max-width: $mobile-point) {
        max-width: none;
      }
    }

    &__items {
      display: flex;
      flex-wrap: wrap;

        @media (max-width: $mobile-point) {
            flex-direction: column;
        }

      h3 {
        display: flex;
        align-items: center;
        margin-bottom: 15px;

        .svg-icon {
          margin-right: 15px;
        }
      }
    }

    &__item {
      display: flex;
      min-width: 50%;
      flex: 1;
      margin-top: 50px;
      flex-direction: column;

        @media (max-width: $mobile-point) {
            margin-top: 25px;
        }

        p {
            max-width: 80%;
        }
    }
  }
</style>