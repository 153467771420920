<template>
  <div class="home">
    <Welcome/>
    <Ticker/>
    <About/>
    <Benefits/>
    <Products/>
    <Team/>
    <Advantages/>
    <Office/>
    <Stack/>
    <Employee/>
    <Career/>
    <Join/>
    <Contacts/>
  </div>
</template>

<script>
import Welcome from '@/components/Home/Welcome.vue';
import Ticker from '@/components/Home/Ticker.vue';
import About from '@/components/Home/About.vue';
import Benefits from '@/components/Home/Benefits.vue';

import Products from '@/components/Home/Products.vue';
import Team from '@/components/Home/Team.vue';

import Advantages from '@/components/Home/Advantages.vue';
import Office from '@/components/Home/Office.vue';

import Stack from '@/components/Home/Stack.vue';
import Employee from '@/components/Home/Employee.vue';
import Career from '@/components/Home/Career.vue';
import Join from '@/components/Home/Join.vue';
import Contacts from '@/components/Form/Contacts'

export default {
  components: {
    Welcome,
    Ticker,
    Benefits,
    About,
    Products,
    Team,
    Advantages,
    Office,
    Stack,
    Employee,
    Career,
    Join,
    Contacts
  }
}
</script>