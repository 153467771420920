import { createApp } from 'vue'
import Notifications from '@kyvg/vue3-notification'
import App from './App.vue'
import Icon from '@/components/UI/SvgIcon.vue';

import { createI18n } from 'vue-i18n'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import router from '@/router/index.js'
import Grecaptcha from '@/grecaptcha.js';

import smoothscroll from 'smoothscroll-polyfill'
smoothscroll.polyfill()

import en from "./langs/en.json";
import ru from "./langs/ru.json";



const i18n = createI18n({
  locale: 'en',
  legacy: true,
  messages: {
    en , ru
  }
})


const app = createApp(App);

const RESERVE_SCROLL_BAR_GAP = 'reserve-scroll-bar-gap';
const options = {
  reserveScrollBarGap: true
};


app.directive('body-scroll-lock',{
  mounted: (el, binding) => {
    if (binding.arg && binding.arg === RESERVE_SCROLL_BAR_GAP && binding.value) {
      disableBodyScroll(el, options);
    } else if (binding.value) {
      disableBodyScroll(el);
    }
  },
  updated: (el, binding) => {
    if (binding.oldValue === binding.value) {
      return;
    }

    if (binding.arg && binding.arg === RESERVE_SCROLL_BAR_GAP && binding.value) {
      disableBodyScroll(el, options);
    } else if (binding.value) {
      disableBodyScroll(el);
    } else {
      enableBodyScroll(el);
    }
  },
  unmounted: (el) => {
    enableBodyScroll(el);
  },
})

app.use(Grecaptcha, { sitekey: '6Ldyd7cUAAAAAKrQiPRvsArENgfiKPiUWqWDFbGP' });
app.use(i18n)
app.use(router)
app.use(Notifications)
app.component("Icon", Icon)
app.mount('#app')
