<template>
  <div class="ticker-wrap">
    <div class="ticker">
      <span class="item-collection-1">
        <div class="item" v-for="item in count" :key="item">
          <Icon name="ticker"></Icon>
        </div>
      </span>
      <span class="item-collection-2">
        <div class="item" v-for="item in count" :key="item">
          <Icon name="ticker"></Icon>
        </div>
      </span>
    </div>
  </div>
</template>
<style lang="scss" scoped>
  .ticker-wrap {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;

    margin-bottom: 175px;

    @media (max-width: $tablet-point) {
      margin-bottom: 100px;
    }

    @media (max-width: $mobile-point) {
      margin-bottom: 50px;
    }
  }

  $time: 50s;

  .ticker {
    display: inline-block;
    animation: marquee $time linear infinite;

    @media (max-width: $mobile-point) {
      animation: marquee 25s linear infinite;
    }
  }

  .item-collection-1 {
    position: relative;
    left: 0;
    animation: swap $time linear infinite;


    @media (max-width: $mobile-point) {
      animation: swap 25s linear infinite;
    }
  }

  .item {
    display: inline-block;
    margin: 0 25px;
  }

  /* Transition */
  @keyframes marquee {
    0% {
      transform: translateX(0)
    }
    100% {
      transform: translateX(-100%)
    }
  }

  @keyframes swap {
    0%, 50% {
      left: 0;
    }
    50.01%,
    100% {
      left: 100%;
    }
  }

  .svg-icon-ticker {
    height: 130px;
    max-width: 362px;
    min-width: 362px;


    @media (max-width: $mobile-point) {
      height: 56px;
      max-width: 247px;
      min-width: 247px;
    }
  }
</style>
<script>
  import Icon from '@/components/UI/SvgIcon.vue';

  export default {
    components: {
      Icon
    },
    computed: {
      count () {
        return Math.ceil(window.innerWidth / 362)
      }
    }
  }
</script>