<template>
  <div>
    <Icon name="place"></Icon>
    {{ $t("form.address") }}
  </div>
  <div>
    <Icon name="mail"></Icon>
    <a href="mailto:info@lofty.today">
      info@lofty.today
    </a>
  </div>
</template>
<script>
  import Icon from '@/components/UI/SvgIcon.vue';

  export default {
    components: {
      Icon
    }
  }
</script>
<style lang="scss" scoped>
  div {
    display: flex;
    margin-bottom: 15px;

    .svg-icon {
      margin-right: 15px;
      height: 24px;
      max-width: 24px;
      min-width: 24px;
    }
  }
  a {
    border-bottom: 1px solid $dark;
  }
</style>