export default {

  install (app, options) {

    app.loadscript = () => {
      return new Promise((resolve) => {
        if(window.grecaptcha) {
          resolve();
        }
        let script = document.createElement('script');
        script.src = "https://www.google.com/recaptcha/api.js?render="+options.sitekey;
        window.document.head.appendChild(script);
        script.onload = () => {
          window.grecaptcha.ready(() => {
            resolve();
          });
        }
      });
    }

    app.config.globalProperties.$grecaptchaLoad = () => {
      app.loadscript();
    }

    app.config.globalProperties.$grecaptchaExecute = async () => {
      await app.loadscript();
      return await window.grecaptcha.execute(options.sitekey, {action: 'login'});
    }
  }
}
