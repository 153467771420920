<template>
  <div class="office">
    <div class="container container--column">
      <h2>{{$t('office.title')}}</h2>
      <div class="office__items">
        <swiper
          :slides-per-view="1.5"
          @init="onInit"
          :loop="true"
          :pagination="{type: 'progressbar', el: '.swiper__pagination'}"
          :breakpoints="breakpoints"
          :space-between="20">
          <swiper-slide v-for="item in items" :key="item">
            <div class="office__item">
              <img :src="'/photos/office/'+item"/>
            </div>
          </swiper-slide>
        </swiper>
      </div>
      <div class="swiper__bottom">
        <div class="swiper__navigation">
          <div class="swiper__prev" @click="swiper.slidePrev()">
            <Icon name="arrow"></Icon>
          </div>
          <div class="swiper__next" @click="swiper.slideNext()">
            <Icon name="arrow"></Icon>
          </div>
        </div>
        <div class="swiper__progress">
          <div class="swiper__pagination"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { Swiper, SwiperSlide } from 'swiper/vue';

  import SwiperCore, { Pagination } from 'swiper';
  SwiperCore.use([Pagination]);

  export default {
    data() {
      return {
        items:[
          'DSCF0594-min.jpg',
          'DSC04045.jpg',
          'DSCF0580-min.jpg',
          'DSC04010.jpg',
          'DSC04139.jpg',
          'DSC03975.jpg',
          'DSCF0606-min.jpg',
          'DSCF0738-min.jpg',
          'DSC04167.jpg',
          'DSCF0714-min.jpg',
          'DSCF0623-min.jpg',
          'DSC04023.jpg',
          'DSC04107.jpg'
        ],
        breakpoints: {
          769: {
            slidesPerView: 2
          },
          1280: {
            slidesPerView: 3
          },
        }
      }
    },
    methods: {
      onInit(swiper) {
        this.swiper = swiper
      }
    },
    components: {
      Swiper,
      SwiperSlide
    }
  }
</script>
<style lang="scss">
  .office {
    &__items {
      width: 100vw;
      max-width: 1810px;
      margin-bottom: 43px;

      @media (max-width: $mobile-point) {
        margin-bottom: 25px;
      }
    }

    &__item {
        display: flex;

        img {
          width: 100%;
          border-radius: 15px;
        }
     }
  }
</style>