<template>
    <footer ref="footer">
        <div class="container">
          <div class="date">©{{new Date().getFullYear()}} by Lofty</div>
          <router-link :to="{ name: 'home'}">
            <Icon name="logo"></Icon>
          </router-link>
          <Social></Social>
        </div>
        <ScrollUp v-if="visibleScroll" :bottom="bottom"></ScrollUp>
    </footer>
</template>

<script>
    import Social from '@/components/Social.vue';
    import ScrollUp from '@/components/ScrollUp'


    export default {
      data () {
        return {
          visibleScroll: false,
          bottom: false
        }
      },
      components: {
        Social,
        ScrollUp
      },
      methods: {
        scrollTop() {
          window.scrollTo(0,0);
        },
        handleScroll() {
            this.bottom = window.innerHeight >= this.$refs.footer.getBoundingClientRect().top;
            this.visibleScroll = window.scrollY > 500
        }
      },
      mounted() {
        window.addEventListener('scroll', this.handleScroll)
      }
    }
</script>

<style scoped lang="scss">
    footer {
        height: 160px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: $white;
        position: relative;

      @media (max-width: $mobile-point) {
        height: auto;
        padding: 30px 0;
      }


      .container {
        justify-content: space-between;
        align-items: center;
        flex-direction: row;
        color: $gray-text;

        @media (max-width: $mobile-point) {
          flex-wrap: wrap;
        }
      }

      .date {
        @media (max-width: $mobile-point) {
          order: 1;
          flex-basis: 100%;
          margin-top: 25px;
          text-align: right;
        }
      }
    }
</style>