<template>
  <div class="advantages">
    <div class="container container--column">
      <section>
          <h2>{{$t('advantages.title')}}</h2>
          <p>{{$t('advantages.description')}}</p>
        <div class="swiper__navigation swiper__navigation--desktop">
          <div class="swiper__prev" @click="swiper.slidePrev()">
            <Icon name="arrow"></Icon>
          </div>
          <div class="swiper__next" @click="swiper.slideNext()">
            <Icon name="arrow"></Icon>
          </div>
        </div>
      </section>
      <div class="advantages__items">
        <swiper
          :slides-per-view="1"
          :loop="true"
          :breakpoints="breakpoint"
          @init="onInit"
          :space-between="20">
          <swiper-slide v-for="item in items" :key="item">
            <div class="advantages__item">
              <Icon :name="item"/>
              <h3>{{$t('advantages.items.'+item+'.title')}}</h3>
              <ul>
                <li v-for="list in 3" :key="'list_'+list">{{$t('advantages.items.'+item+'.list.'+list)}}</li>
              </ul>
            </div>
          </swiper-slide>
        </swiper>
      </div>
      <div class="swiper__navigation swiper__navigation--mobile">
        <div class="swiper__prev" @click="swiper.slidePrev()">
          <Icon name="arrow"></Icon>
        </div>
        <div class="swiper__next" @click="swiper.slideNext()">
          <Icon name="arrow"></Icon>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { Swiper, SwiperSlide } from 'swiper/vue';

  export default {
    data() {
      return {
        items: ['clenched', 'hands', 'team', 'support'],
        swiper: null,
        breakpoint: {
          1320: {
            slidesPerView: 2
          },
          2160: {
            slidesPerView: 3
          },
        }
      }
    },
    methods: {
      onInit(swiper) {
        this.swiper = swiper
      }
    },
    components: {
      Swiper,
      SwiperSlide
    }
  }
</script>


<style lang="scss" scoped>
  .advantages {
    position: relative;
    margin: 150px 0;

    @media (max-width: $mobile-point) {
      margin: 100px 0;
    }


    .container {
      min-height: 420px;
      justify-content: center;

      section {
        max-width: 40%;

        @media (max-width: $mobile-point) {
          max-width: none;
        }

          h2 {
              margin-bottom: 25px;
          }
          p {
              margin-bottom: 50px;
          }
      }
    }

    h3 {
      margin-bottom: 50px;
    }

    &__item {
      padding: 50px;
      background: $white;
      border-radius: 15px;
      width: 100%;

      h3 {
        margin-bottom: 25px;
      }

      .svg-icon {
        min-width: 48px;
        max-width: 48px;
        height: 48px;
        margin-bottom: 25px;
      }

      ul {
        margin: 0;
        list-style: none;
        padding: 0;


        li {
          display: flex;
          align-items: center;
          margin-bottom: 25px;


          &:last-child {
            margin-bottom: 0;
          }


          &:before {
            content: '';
            min-width: 16px;
            max-width: 16px;
            height: 2px;
            background: $accent;
            margin-right: 15px;
            display: flex;
          }

        }
      }
    }

    &__items {
      position: absolute;
      top: 0;
      left: 50%;
      width: 50%;
      max-width: 1810px;

      @media (max-width: $mobile-point) {
        width: 100%;
        position: relative;
        left: 0;
        margin-bottom: 25px;
      }
    }
  }
</style>