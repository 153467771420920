<template>
  <div class="join">
    <div class="container container--column">
      <div class="join__title">
        {{$t('join.title')}}
      </div>
      <div class="join__description">
        {{$t('join.description')}}
      </div>
      <router-link class="btn btn__accent" :to="{ name: 'jobs', hash: '#vacancy'}">{{ $t("join.btn") }}</router-link>
    </div>
  </div>
</template>
<style lang="scss">
  .join {
    background-color: $dark;
    background-image: url("/photos/join_bg.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: right;
    color: $white;
    padding: 100px 0;

    @media (max-width: $mobile-point) {
      padding: 50px 0;
      background-position: left;
      background-image: url("/photos/join_bg--mobile.jpg");
    }

    .container {
      align-items: center;
    }

    &__title {
      font-weight: 700;
      font-size: 46px;
      line-height: 125%;
      text-align: center;
      margin-bottom: 25px;

      @media (max-width: $mobile-point) {
        font-size: 36px;
        margin-bottom: 15px;
      }
    }

    &__description {
      text-align: center;
      max-width: 50%;
      margin-bottom: 50px;
      background-color: $dark;

      @media (max-width: $mobile-point) {
        max-width: none;
        margin-bottom: 25px;
        background: transparent;
      }
    }
  }
</style>