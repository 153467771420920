<template>
  <div class="stack block">
    <div class="container">
      <div class="stack__left">
        <div>
          <h3>{{$t('stack.title')}}</h3>
          <p>{{$t('stack.description')}}</p>
        </div>
        <ul>
          <li v-for="(item, key, index) in departments" :key="key">
            <router-link :to="{ name: 'jobs', hash: '#vacancy',  query: { filter:  key } }">
              <span class="stack__count">{{index + 1}}</span>{{item}}
            </router-link>
          </li>
        </ul>
      </div>
      <div class="stack__right">
       <div class="stack__ring">
         <div class="stack__icon" v-for="item in secondCircle" :key="item">
           <div class="stack__box" :data-title="item">
             <Icon :name="item"></Icon>
           </div>
         </div>
       </div>
        <div class="stack__circle">
          <div class="stack__icon" v-for="item in firstCircle" :key="item">
            <div class="stack__box" :data-title="item">
              <Icon :name="item"></Icon>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { DEPARTMENTS } from "@/constants.js"

  export default {
    data() {
      return {
        firstCircle: ['golang', 'js', 'php'],
        secondCircle: ['mysql', 'clickhouse', 'laravel', 'vue', 'docker', 'git', 'ansible'],
        departments: DEPARTMENTS
      }
    }
  }
</script>
<style lang="scss" scoped>
  .stack {
    $container-size-circle: 360px;
    $element-size: 60px;
    $container-size-ring: 560px;

    @media (max-width: $tablet-point) {
      $element-size: 56px;
      $container-size-circle: 260px;
      $container-size-ring: 460px;
    }


    &__left, &__right {
      flex: 1;
    }


    h3 {
      margin-bottom: 25px;
    }

    p {
      margin-bottom: 50px;
    }

    li {
      margin-bottom: 15px;
      font-weight: 600;
      font-size: 18px;
    }

    &__count {
      margin-right: 10px;
      color: $gray-text;
      font-weight: 400;
      font-size: 14px;
    }


    &__right {
      display: flex;
      justify-content: center;
      align-items: center;


      @media (max-width: $tablet-point) {
        display: none;
      }
    }



    &__ring {
      border-radius: 50%;
      border: 3px solid $accent;
      padding: 100px;
      position: relative;
      min-width:   $container-size-ring;
      max-width:   $container-size-ring;
      height:   $container-size-ring;
      transform: rotate(180deg);

      .stack__icon {
        transform-origin: 50% calc(#{$element-size} / 2 -  #{$container-size-ring} / 2)
      }
    }

    &__circle {
      min-width:  $container-size-circle;
      max-width:  $container-size-circle;
      height:  $container-size-circle;
      border-radius: 50%;
      position: absolute;
      border: 50px solid $accent;
      transform: rotate(180deg);

      @media (max-width: $tablet-point) {
        border: 40px solid $accent;
      }


      .stack__icon {
        transform-origin: 50% calc(#{$element-size} / 2 -  (#{$container-size-circle} - 100px) / 2);
      }
    }

    &__icon {
      min-width: $element-size;
      max-width: $element-size;
      background: $white;
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      width: $element-size;
      height: $element-size;
      border-radius: $element-size;
      left: 50%;
      top: 100%;

      .svg-icon {

      }

      &:hover {
        &:after {
          display: flex;
        }
      }
    }

    &__box {
      display: flex;

      &:after {
        display: none;
        content: attr(data-title);
        background: $gray;
        border-radius: 5px;
        color: $dark;
        padding: 5px 15px;
        position: absolute;
        text-transform: capitalize;
        align-items: center;
        left: 50%;
        bottom: 100%;
        transform: translate(-50%, -20px);
      }
    }

    @for $i from 1 through 9 {
      @for $j from 1 through $i {
        .stack__icon:nth-child(#{$j}):nth-last-child(#{$i - $j}) {
          transform: translate(-50%, -50%) rotate(360deg * calc(($j - 1) / ($i - 1)));

          .stack__box{
            transform: rotate(-360deg * calc(($j - 1) / ($i - 1)) + 180deg);
          }
        }
      }
    }
  }

  .no-touch {
    .stack li a {
      &:hover {
        color: $gray-text;
      }
    }

    .stack__icon {
      &:hover {
        .stack__box {
          &:after {
            display: flex;
          }
        }
      }
    }
  }
</style>