<template>
  <div class="select" :class="{open: state, disabled: disabled, 'select-right': align == 'right'}">
    <div class="select__label" @click="toggle">
      <slot name="label"></slot>
      <icon name="chevron" v-if="chevron"></icon>
    </div>
    <div class="select__list" v-if="state" @click.stop="hide()">
      <slot name="list"></slot>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        state: false
      }
    },
    props: {
      disabled: {
        type: Boolean,
        default: false
      },
      align: {},
      chevron: {
        type: Boolean,
        default: true
      }
    },
    methods: {
      toggle() {
        if(this.disabled) {
          return;
        }

        this.state = !this.state;
      },
      hide() {
        this.state = false;
      }
    }
  }
</script>
<style lang="scss">
  .select {
    position: relative;

    &.open {
      .select__label {
        &::after {
          transform: rotate(270deg);
        }
      }
    }

    &.disabled {
      .select__label {
        opacity: 0.5;
        cursor: no-drop;
      }
    }

    &-right {
      .select__list {
        right: 0;
      }
    }

    &__label {
      display: flex;
      cursor: pointer;
      align-items: center;
      font-size: 16px;


      .label, .svg-icon {
        color: $dark;
      }


      .label {
        flex-grow: 1;
        display: flex;
        align-items: center;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }


      &:hover {
        .label, .svg-icon {
          color: $accent;
        }
      }



      .svg-icon-chevron {
        margin-left: 4px;

        height: 16px;
        min-width: 16px;
        max-width: 16px;
      }
    }

    &__list {
      position: absolute;
      display: flex;
      flex-direction: column;
      top: 100%;
      max-height: 300px;
      min-width: 100%;
      z-index: 2;
      overflow-y: auto;
      right: 0;
      border-radius: 4px;
      padding: 8px;
      margin-top: 8px;

      &::-webkit-scrollbar {
        width: 4px;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 2px;
      }

      .list {
        flex-grow: 1;
      }

      ul {
        list-style: none;
        margin: 0 !important;
        border-radius: 4px;

        li {
          white-space: normal;
          padding: 8px 12px;
          cursor: pointer;
          display: flex;
          align-items: center;
          overflow: hidden;
          text-overflow: ellipsis;
          border-radius: 4px;

          i {
            margin-right: 8px;
          }
        }
      }
    }
  }

  .select__list ul li {
    &:hover {
      color: $accent;
    }
  }
</style>
