<template>
  <div class="block">
    <div class="container container--column" id="products">
      <h2>{{$t("products.title")}}</h2>
      <div class="products">
        <swiper
          :slides-per-view="1.5"
          :slidesPerColumnFill="'row'"
          :slidesPerColumn="1"
          @init="onInit"
          :breakpoints="breakpoints"
          :pagination="{type: 'progressbar', el: '.swiper__pagination--product'}"
          :space-between="20">
          <swiper-slide v-for="item in items" :key="item">
            <div class="products__item">
              <p>
                {{$t("products.items."+item)}}
              </p>
              <Icon :name="item"></Icon>
            </div>
          </swiper-slide>
        </swiper>
      </div>
      <div class="swiper__bottom swiper__bottom--mobile">
        <div class="swiper__navigation">
          <div class="swiper__prev" @click="swiper.slidePrev()">
            <Icon name="arrow"></Icon>
          </div>
          <div class="swiper__next" @click="swiper.slideNext()">
            <Icon name="arrow"></Icon>
          </div>
        </div>
        <div class="swiper__progress">
          <div class="swiper__pagination--product"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { Swiper, SwiperSlide } from 'swiper/vue';

  export default {
    data() {
      return {
        items: ['development', 'directions', 'b2b', 'world', 'integration', 'focus'],
        breakpoints: {
          480: {
            slidesPerColumn: 1,
            slidesPerView: 2
          },
          768: {
            slidesPerColumn: 3,
            slidesPerView: 3
          }
        },
        swiper: null
      }
    },
    components: {
      Swiper,
      SwiperSlide
    },
    methods: {
      onInit(swiper) {
        this.swiper = swiper
      },
      toggleSwiper() {
        if (window.innerWidth > 768) {
          this.swiper.disable()
        } else {
          this.swiper.enable()
        }
      }
    },
    mounted() {
      this.toggleSwiper();
      window.addEventListener('resize', this.toggleSwiper)
    },
    unmounted() {
      window.removeEventListener("resize", this.toggleSwiper);
    },

  }
</script>
<style lang="scss">
  .products {
    &__item {
      background: $white;
      border-radius: 15px;
      flex-direction: column;
      display: flex;
      padding: 25px;
      min-height: 172px;
      justify-content: space-between;
      font-weight: 600;
      font-size: 18px;
      line-height: 140%;
      width: 100%;
    }
  }
</style>