<template>
  <div class="social">
    <a @mouseover="isShow = true" @mouseleave="isShow = false" href="https://www.instagram.com/loftytoday/" target="_blank">
      <Icon name="instagram" v-if="!isShow || $root.isTouch"/>
      <Icon name="instagram2" v-else/>
    </a>
    <a :href="item" target="_blank" v-for="(item , key) in items" :key="key">
      <Icon :name="key"></Icon>
    </a>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        isShow: false,
        items: {
          'hh': 'https://hh.ru/employer/4137277',
          'linkedin': 'https://www.linkedin.com/company/34659205',
          'habr': 'https://career.habr.com/companies/loftytoday'
        }
      }
    }
  }
</script>
<style lang="scss">
  .social {
    display: flex;

    a {
      display: flex;
      margin-right: 24px;

      @media (max-width: $mobile-point) {
        margin-right: 15px;
      }

      &:last-of-type {
        margin-right: 0;
      }

      .svg-icon {
        transition: 0.3s;

        @media (max-width: $tablet-point) {
          height: 24px;
          max-width: 24px;
          min-width: 24px;
        }
      }
    }
  }
  .no-touch {
    .social {
      a {
        &:hover {
          .svg-icon {
            &-hh {
              color: #D20A11;
            }

            &-linkedin {
              color: #0274B3;
            }

            &-habr {
              color: #91B5C3;
            }
          }
        }
      }
    }
  }
</style>