<template>
    <header>
      <div class="container">
        <div class="logo__wrapper">
          <router-link :to="{ name: 'home'}">
            <Icon name="logo"></Icon>
          </router-link>

          <Select>
            <template v-slot:label>
              <div class="label lang__item">
                <Icon name="globe"></Icon>
                {{$i18n.locale}}
              </div>
            </template>
            <template v-slot:list>
              <ul>
                <li class="lang__item" :class="{'lang__item--current': $i18n.locale === item}" v-for="item in langs" :key="item" @click="changeLang(item)">{{item}}</li>
              </ul>
            </template>
          </Select>
        </div>

        <div class="nav">
            <Nav/>
        </div>

        <Menu></Menu>
        <Social></Social>
      </div>
    </header>
</template>

<script>
    import Menu from './Menu'
    import Social from '@/components/Social.vue';
    import Select from '@/components/UI/Select.vue';
    import Nav from '@/components/Nav.vue';

    export default {
        components: {
          Menu,
          Social,
          Select,
          Nav
        },
        data() {
          return {
            langs:['en','ru']
          }
        },
        methods: {
          changeLang(lang) {
              this.$i18n.locale = lang;
          }
        }
    }
</script>

<style lang="scss">
    header  {
        height: 108px;

      @media (max-width: $mobile-point) {
        height: 86px;
      }

      .container {
        height: 100%;
        align-items: center;
        justify-content: space-between;
      }

      .social {
        @media (max-width: $medium-point) {
          display: none;
        }
      }
    }

    .nav  {
        @media (max-width: $tablet-point) {
            display: none;
        }

        a, span {
          margin-right: 24px;
          cursor: pointer;

            &:last-child {
                margin-right: 0;
            }

        }
    }

    .logo__wrapper {
      display: flex;
      align-items: center;

      .select {
        margin-left: 50px;

        @media (max-width: $medium-point) {
          margin-left: 25px;
        }
      }
    }

    .lang {
      &__item {
        text-transform: uppercase;

        .svg-icon {
          height: 16px;
          min-width: 16px;
          max-width: 16px;
          margin-right: 4px;
        }

        &--current {
          color: $accent;
        }
      }
    }
</style>