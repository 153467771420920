import axios from 'axios';

export const getJobs = (specialization = '', lang = "EN") => {

    const spec = specialization ? `&professional_role=${specialization}` : ''
    return axios.get(`https://api.hh.ru/vacancies?per_page=100&employer_id=4137277&locale=${lang}${spec}`)
}

export const getJob = (id) => {
    return axios.get(`https://api.hh.ru/vacancies/${id}?locale=RU`)
}

export const sendContact = (data) => {
  let formData = new FormData();

  for (let key in data) {
    formData.append(key, data[key])
  }

  return axios.post(`/eml.php`, formData)
}