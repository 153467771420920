<template>
  <div class="jobs">
    <div class="jobs__filters">
      <div class="jobs__filter--wrap">
        <router-link
          :class="{'jobs__filter--active': 'all' === currentFilter }"
          class="jobs__filter"
          :to="{ name: 'jobs', hash: '#vacancy' }">
          {{$t('stack.list.all')}}
        </router-link>
      </div>
      <div class="jobs__filter--wrap" v-for="(item, key) in departments" :key="key">
        <router-link
          :class="{'jobs__filter--active': key === currentFilter }"
          class="jobs__filter"
          :to="{ name: 'jobs', hash: '#vacancy', query: { filter:  key } }">
          {{item}}
        </router-link>
      </div>
    </div>
    <div class="jobs__items" v-if="isLoading" >
      <Job v-for="index in 12" :key="`job_${index}`" :params="{}"></Job>
    </div>
    <div class="jobs__items" v-else-if="jobs.length">
      <Job v-for="(job, index) in jobs" :key="`job_${index}`" :params="job"></Job>
    </div>
    <div class="jobs__empty" v-else >
        {{$t('jobs.empty')}}
    </div>
  </div>
</template>
<script>
  import { getJobs } from "@/api";
  import Job from "@/components/Jobs/JobItem.vue"
  import { DEPARTMENTS, DEPARTMENTS_ITEMS } from "@/constants.js"

  export default {
    components: {
      Job
    },
    data() {
      return {
        isLoading: false,
        jobs: [],
        currentFilter: 'all',
        departments: DEPARTMENTS,
        departmentsItems: DEPARTMENTS_ITEMS
      }
    },
    mounted() {
      if (this.$route.query.filter && this.departments[this.$route.query.filter]) {
        this.currentFilter = this.$route.query.filter;
      }
      this.getJobs();
    },
    methods: {
      async getJobs() {

        this.isLoading = true;

        try {
           const response =  await getJobs(this.departmentsItems[this.currentFilter], this.$i18n.locale.toUpperCase())
            this.jobs = response.data.items;
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error(e)
        } finally {
          this.isLoading = false;
        }

      }
    }
  }
</script>
<style lang="scss">
  .jobs {

    &__items {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 20px;
      margin-top: 33px;

      @media (max-width: $tablet-point) {
        grid-template-columns: repeat(2, 1fr);
      }
      @media (max-width: $mobile-point) {
        display: flex;
        flex-direction: column;
      }
     }

    &__filters {
      display: flex;
      flex-wrap: wrap;

      @media (max-width: $tablet-point) {
        flex-wrap: nowrap;
        overflow: auto;

        &::-webkit-scrollbar {
          display: none;
        }

      }
    }

    &__filter {
      border: 1px solid $dark;
      box-sizing: border-box;
      border-radius: 20px;
      padding: 5px 10px;

      font-weight: 400;
      font-size: 14px;
      line-height: 140%;
      margin-bottom: 15px;
      margin-right: 14px;
      white-space: nowrap;

      color: $dark;
      cursor: pointer;

      &--wrap {
        display: flex;
        align-items: center;
      }

      &--active {
        background: $accent!important;
        border: 1px solid $accent;
        cursor: default;
        color: $dark!important;
      }
    }

      &__empty {
          border-bottom: 1px solid rgba(46, 57, 72, 0.08);
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 50px 0;
          font-size: 18px;
      }
  }

  .no-touch {
    .jobs__filter {

      &:hover {
        background: $dark;
        color: $white;
      }
    }

    .jobs__filters {
      @media (max-width: $tablet-point) {
        flex-wrap: wrap;
      }
    }

  }
</style>
