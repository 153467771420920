<template>
  <div class="about" id="about">
    <div class="container">
      <div class="about__title">
        <h3>
          {{$t("about.title")}}
        </h3>
        <p>
          {{$t("about.description")}}
        </p>
      </div>
      <div class="stat">
        <div class="stat__item" v-for="(item, index) in stats" :key="`stat_${index}`">
          <div class="stat__item--count">
            {{item.count}}
          </div>
          <div class="stat__item--label">
            {{item.label}}
          </div>
        </div>
      </div>
      <Years></Years>
    </div>
  </div>
</template>
<script>
  import Years from '@/components/Home/Years.vue';

  export default {
    computed: {
      stats() {
        return [
          {count: '320+', label: this.$t("stats.employees")},
          {count: '15+', label: this.$t("stats.projects") },
          {count: '200+', label: this.$t("stats.countries")}
        ]
      }
    },
    components: {
      Years
    }
  }
</script>
<style lang="scss">
  .stat {
    height: 328px;
    align-items: center;
    justify-content: space-around;
    border-radius: 4px;
    background: $accent;
    display: flex;
    width: 100%;
    
    @media (max-width: $tablet-point) {
      height: auto;
      padding: 50px 20px;
      align-items: flex-start;
      justify-content: space-between;
    }


    @media (max-width: $mobile-point) {
      width: calc(100% + 40px);
      margin-left: -20px;
      margin-right: -20px;
    }

    &__item {
      display: flex;
      flex-direction: column;

      &:last-child {
        margin-right: 0;
      }

      &--label {
        @media (max-width: $tablet-point) {
          width: min-content;
        }
      }

      &--count {
        color: $dark;
        font-style: normal;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 65px;
        line-height: 125%;
        position: relative;
        padding-bottom: 4px;
        margin-bottom: 16px;


        @media (max-width: $tablet-point) {
          font-size: 50px;
        }

        @media (max-width: $mobile-point) {
          font-size: 32px;
        }


        &:after {
          content: '';
          position: absolute;
          bottom: 0;
          height: 5px;
          border-radius: 4px;
          width: 54px;
          background: $dark;
          left: 0;
        }
      }
    }
  }
  .about {
    .container {
      flex-direction: column;
      align-items: center;
    }

    &__title {
      margin-bottom: 100px;
      max-width: 60%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;

      @media (max-width: $tablet-point) {
        max-width: 100%;
        margin-bottom: 50px;
      }

      h3 {
        padding-bottom: 25px;
        margin-bottom: 25px;
        position: relative;


        &:after {
          content: '';
          width: 183px;
          height: 5px;
          background: $accent;
          bottom: 0;
          position: absolute;
          left: 50%;
          transform: translate(-50%, 0);
        }
      }

      p {
        text-align: center;
      }
    }
  }
</style>