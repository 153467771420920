<template>
  <div class="contacts block">
    <div class="container" id="contact">
      <div class="contacts__form">
        <div class="contacts__form--left">
          <h3>{{$t('form.title')}}</h3>
          <p>{{$t('form.subtitle')}}</p>
          <Address/>
        </div>
        <div class="contacts__form--right">
          <Sample/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Sample from '@/components/Form/Sample.vue';
  import Address from '@/components/Form/Address.vue';

  export default {
    components: {
      Sample,
      Address
    }
  }
</script>

<style scoped lang="scss">
  .contacts {
    &__form {
      display: flex;
      width: 100%;
      border: 1px solid $gray-text;
      border-radius: 15px;

      @media (max-width: $mobile-point) {
        flex-direction: column;
        margin-bottom: 50px;
      }

      &--left {
        flex: 1;
        display: flex;
        flex-direction: column;
        background-color: $dark;
        color: $white;
        border-radius: 15px 0 0 15px;
        background-image: url("/photos/form-bg.jpg");
        background-size: cover;
        background-repeat: no-repeat;

        @media (max-width: $mobile-point) {
          border-radius: 15px 15px 0 0;
          background-image: url("/photos/form-bg-mobile.jpg");
        }

        div {
          display: flex;
          margin-bottom: 15px;

          .svg-icon {
            margin-right: 15px;
            height: 24px;
            max-width: 24px;
            min-width: 24px;
          }
        }

        h3 {
          margin-bottom: 15px;
          color: $white;

          @media (max-width: $tablet-point) {
            font-size: 20px;
          }

          @media (max-width: $mobile-point) {
            font-size: 18px;
          }
        }

        p {
          margin-bottom: 50px;
        }

        a {
          border-bottom: 1px solid $white;
        }
      }
      &--right {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .input, .textarea {
          margin-bottom: 15px;
        }
      }

      &--left, &--right {
        padding: 50px;
        @media (max-width: $tablet-point) {
          padding: 20px;
        }
      }
    }
  }
</style>