<template>
    <div class="contacts block">
      <div class="container" id="contact">
        <div class="contacts__form">
          <div class="contacts__form--left">
            <h2>{{ $t("form.contacts-us") }}</h2>
            <Sample/>
          </div>
          <div class="contacts__form--right">
            <h3>{{$t('form.contacts')}}</h3>
            <Address/>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
    import Sample from '@/components/Form/Sample.vue';
    import Address from '@/components/Form/Address.vue';

    export default {
        components: {
          Sample,
          Address
        }
    }
</script>

<style scoped lang="scss">
    .contacts {
        &__form {
            display: flex;
            width: 100%;

            @media (max-width: $mobile-point) {
              flex-direction: column;
            }

            h2 {
                margin-bottom: 25px;
            }

            &--left {
                flex: 1;
                display: flex;
                flex-direction: column;
                padding-right: 50px;
                margin-right: 50px;
                align-items: flex-start;


                @media (max-width: $tablet-point) {
                  padding-right: 30px;
                  margin-right: 30px;
                }


                @media (max-width: $mobile-point) {
                  padding: 0;
                  margin-right: 0;
                  margin-bottom: 50px;
                }
            }
            &--right {
                flex: 1;
                display: flex;
                flex-direction: column;

                h3 {
                  margin-bottom: 26px;
                }
            }
        }
    }
</style>