<template>
  <div class="years" ref="years">
    <div class="years__item" v-for="(item, key) in years" :key="item">
      <div class="years__title" :class="{ 'years__title--first' : key === 0 }">
        <h3>{{item}}</h3>
      </div>
      <div class="years__description" :class="{ 'years__description--first' : key === 0 }">
        <div class="years__description--line"></div>
        <div class="years__description--circle"></div>
        {{$t('years.'+item)}}
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        top: 0
      }
    },
    methods: {
      handleScroll() {
        this.$refs.years.querySelectorAll('.years__item').forEach(item => {
          const line = item.querySelector('.years__description--line');
          const title = item.querySelector('.years__title h3');
          const circle = item.querySelector('.years__description--circle');
          let size = 0;

          if (item.getBoundingClientRect().top < 230) {
            if (item.getBoundingClientRect().top < 186) {
              circle.classList.add('active')
            } else {
              circle.classList.remove('active')
            }

            size = 230 - item.getBoundingClientRect().top;

            if (size > 100) {
              size = 100
            }

            line.style.height = size +'%';
            title.style.background = 'linear-gradient(rgba(46, 57, 72, 1) '+size+'%, transparent ' +size+'%)'
          } else {
            
            circle.classList.remove('active')
            size = 0;
            line.style.height = size +'%';
            title.style.background = 'linear-gradient(rgba(46, 57, 72, 1) '+size+'%, transparent ' +size+'%)'
          }
        })
      }
    },
    computed: {
      years () {
        const array = [];
        for (let i = 2011; i < 2024; i++) {
          if (i !== 2013) {
            array.push(i)
          }
        }
        return array
      }
    },
    mounted() {
      window.addEventListener('scroll', this.handleScroll);
    },
    unmounted() {
      window.removeEventListener("scroll", this.handleScroll);
    }
  }
</script>
<style lang="scss">
  .years {
    width: 100%;
    margin-bottom: 116px;

    h3 {
      font-weight: 900;
      font-size: 104px;
      line-height: 125%;
      text-transform: uppercase;

      display: inline-block;
      color: transparent;
      -webkit-text-stroke: 1px $dark;
      text-stroke: 1px $dark;

      -webkit-background-clip: text!important;
      -moz-background-clip: text!important;
      -webkit-text-fill-color: transparent;

      @media (max-width: $tablet-point) {
        font-size: 65px;
      }


      @media (max-width: $mobile-point) {
        font-size: 46px;
      }
    }


    &__item {
      display: flex;
    }

    &__title {
      flex: 1;
      padding: 50px 0;
      align-items: center;
      display: flex;

      &--first {
        h3 {
          background: linear-gradient(rgba(46, 57, 72, 1) 100%, transparent 100%)!important;
          -webkit-background-clip: text!important;
          -moz-background-clip: text!important;
        }
      }
    }

    &__description {
      flex: 1;
      font-weight: 500;
      font-size: 29px;
      line-height: 140%;
      padding: 50px 38px;
      padding-right: 0;
      position: relative;
      display: flex;
      align-items: center;

      @media (max-width: $tablet-point) {
        font-size: 20px;
      }

      @media (max-width: $mobile-point) {
        font-size: 16px;
      }


      &:after {
        content: '';
        position: absolute;
        height: 100%;
        left: 0;
        top: 0;
        width: 5px;
        background: $gray;
      }

      &--line {
        content: '';
        position: absolute;
        height: 0;
        left: 0;
        top: 0;
        width: 5px;
        background: $accent;
        z-index: 1;
      }

      &--first {

        .years__description--line {
          height: 100%!important;
        }

        .years__description--circle {
          &:before {
            display: flex;
          }
        }
      }

      &--circle {
        content: '';
        position: absolute;
        width: 32px;
        height: 32px;
        left: 0;
        top: 50%;
        background: $gray;
        z-index: 1;
        border-radius: 50%;
        transform: translate(-14px, -50%);

        &:after {
          content: '';
          position: absolute;
          width: 14px;
          height: 14px;
          left: 50%;
          top: 50%;
          background: $white;
          z-index: 1;
          border-radius: 50%;
          transform: translate(-50%, -50%);
        }

        &:before {
          content: '';
          position: absolute;
          width: 32px;
          height: 32px;
          left: 50%;
          top: 50%;
          background: $accent;
          z-index: 1;
          border-radius: 50%;
          transform: translate(-50%, -50%);
          display: none;
        }

        &.active {
          &:before {
            display: flex;
          }
        }
      }
    }
  }
</style>