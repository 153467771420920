<template>
  <div :class="{'no-touch': !isTouch}">
    <Header></Header>
    <router-view :key="keyRouter" />
    <Footer></Footer>
    <notifications group="foo" position="top left" />
  </div>
</template>
<script>
  import Header from '@/components/Header'
  import Footer from '@/components/Footer'
  import './styles/index.scss'

  export default {
    components: {
      Header,
      Footer
    },
    data() {
      return {
        isTouch: false
      }
    },
    computed: {
      keyRouter() {
        if (this.$route.fullPath.includes("/jobs/")) {
          return this.$route.params.id;
        }

        return this.$route.fullPath;
      }
    },
    methods: {
      checkTouch(){
        /* eslint-disable */
        if (('ontouchstart' in window) || window.DocumentTouch && document instanceof DocumentTouch) {
          this.isTouch = true;
        }
        /* eslint-enable */
      }
    },
    mounted() {
      this.checkTouch();
    }
  }
</script>