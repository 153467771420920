<template>
    <div class="container">
      <div class="jobs__welcome">
        <div class="jobs__img"></div>
        <h1>{{$t("jobs.head")}}<Icon name="hand"/></h1>
        <p>
          {{$t("jobs.description")}}
        </p>
        <router-link class="btn" :to="{ name: 'jobs', hash: '#vacancy'}">
          {{$t("jobs.btn")}} <Icon name="arrow-down"/>
        </router-link>
      </div>
    </div>
    <Ticker/>
    <div class="container container--column" id="vacancy">
        <h2>{{$t("jobs.title")}}</h2>
        <JobsList/>
        <Career/>
    </div>
    <div class="jobs__bottom"></div>
    <FormVacancies/>
</template>

<script>
    import JobsList from "@/components/Jobs/JobsList.vue";
    import Ticker from '@/components/Home/Ticker.vue';
    import Career from '@/components/Home/Career.vue';
    import FormVacancies from '@/components/Form/Vacancies.vue'

    export default {
       components:{
         JobsList,
         Ticker,
         Career,
         FormVacancies
       }
    }
</script>
<style lang="scss">
  .jobs {

    &__img {
      height: 500px;
      width: 100%;
      background-image: url("/photos/jobs.png");
      background-size: cover;
      background-repeat: no-repeat;
      margin-bottom: 25px;
      background-position: center;

      @media (max-width: $mobile-point) {
        height: 260px;
        margin-left: -20px;
        margin-right: -20px;
        width: calc(100% + 40px);
      }
    }

    &__bottom {
      background-image: url("/photos/DSCF0674-min.jpg");
      height: 800px;
      width: 100%;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;

      @media (max-width: $mobile-point) {
        height: 480px;
      }
    }
    &__welcome {
        display: flex;
        flex-direction: column;
        margin-bottom: 100px;
        align-items: center;
        width: 100%;

        h1 {
          margin-bottom: 15px;
          text-transform: none;
          text-align: center;

          .svg-icon {
            margin-left: 12px;
            height: 40px;
            max-width: 40px;
            min-width: 40px;

            @media (max-width: $tablet-point) {
              height: 32px;
              max-width: 32px;
              min-width: 32px;
            }
          }
        }
        p {
          margin-bottom: 25px;
          max-width: 590px;
          text-align: center;
        }

      .btn {
        .svg-icon {
          height: 24px;
          max-width: 24px;
          min-width: 24px;
          margin-left: 5px;
          color: inherit;
        }
      }
     }
  }
</style>