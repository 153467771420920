<template>
  <router-link class="job" v-if="params.id" :to="{name: 'job', params: { id: params.id  } }">
      <div class="job__title">{{params.name}}</div>
      <div class="job__info">
          <span class="job__info--item job__info--salary" v-if="params.salary">
              <Icon name="cash" />
              <span v-if="params.salary.from">{{$t('jobs.salary-from')}} {{params.salary.from}} ₽.</span>
              <span v-if="params.salary.to">{{$t('jobs.salary-to')}} {{params.salary.to}} ₽.</span>
          </span>
          <span class="job__info--item" v-if="params.area">
              <Icon name="point" />
              {{params.area.name}}
          </span>
          <span class="job__info--item"  v-if="params.schedule">
              <Icon name="clock" />
              {{params.schedule.name}}
          </span>
          <span class="job__info--item"  v-if="params.experience">
              <Icon name="bag" />
              {{params.experience.name}}
          </span>
      </div>
      <div class="job__arrow">
        <Icon name="arrow3" />
      </div>
<!--      <div class="job__snippet" v-if="params.snippet">{{params.snippet.responsibility}}</div>-->
  </router-link>
    <div class="job" v-else></div>
</template>

<script>
  export default {
    props: ['params']
  }
</script>

<style scoped lang="scss">
    .job {
        display: flex;
        border-radius: 12px;
        padding: 24px;
        flex-direction: column;
        min-height: 100px;
        transition: box-shadow .3s;
        border: 1px solid $gray;
        position: relative;
        border: 1px solid rgba(46, 57, 72, 0.10);
        background: #F2F4FA;


      &__title {
          margin-bottom: 12px;
          font-size: 24px;
          font-weight: 700;
          line-height: 29px;
        }

        &__info {
            display: flex;
            flex-wrap: wrap;
            gap: 4px 8px;

            &--item {
                display: flex;
                align-items: center;
                box-sizing: border-box;
                background-color: $gray-icons;
                border-radius: 20px;
                padding: 5px 10px;
                gap: 4px;
                flex-wrap: wrap;

                font-weight: 400;
                font-size: 14px;
                line-height: 140%;

                &:last-child {
                    margin-right: 0;
                }
                svg {
                  height: 16px;
                  max-width: 16px;
                  min-width: 16px;
                }
            }

            &--salary {
              background-color: $dark;
              color: $white;
            }
        }

        &__snippet {
          margin-top: 24px;
          padding-top: 12px;
        }

        &__arrow {
          opacity: 0;
          position: absolute;
          right: 24px;
          bottom: 24px;
          transition-timing-function: ease-in-out;
          transition-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
          transition: 0.3s;
          transform: translateX(-200%);
          svg {
            max-width: 14px;
            min-width: 14px;
            height: 18px;
          }
        }
    }

    .no-touch {
      .job {
        &:hover {
          border-radius: 12px;
          border: 1px solid rgba(46, 57, 72, 0.25);
          box-shadow: 0px 0px 16px 0px rgba(46, 57, 72, 0.05);

          .job__arrow {
            transform: translateX(0);
            opacity: 1;
          }
        }
      }
    }
</style>