<template>
    <div class="position container container--column">
      <div class="link-arrow__wrapper">
        <router-link class="link-arrow" :to="{ name: 'jobs', hash: '#vacancy'}">
          {{ $t("jobs.back") }}
          <span class="bg"></span>
        </router-link>
      </div>
      <div class="position-wrapper">
          <div class="position position-empty"  v-if="isLoading"></div>
          <div ref="position">
            <div class="position__title">
              <h2>{{position.name}}</h2>
              <a :href="position.apply_alternate_url" target="_blank" class="btn">{{$t("form.respond")}}</a>
              <div class="position-meta">
              <span v-if="position.employment">
                {{ position.employment.name }}
              </span>
                <span class="spacer"> - </span>
                <span v-if="position.experience">
               {{ position.experience.name }}
              </span>
              </div>
            </div>
            <div class="description" v-html="position.description"></div>
          </div>
      </div>
    </div>
</template>

<script>
    import {getJob} from "../api";

    export default {
        props:['id'],
        mounted() {
          this.getData()
        },
        data() {
            return {
                isLoading: false,
                description: null,
                name: '',
                position: {}
            }
        },
        methods: {
            async getData() {

                this.isLoading = true;

                try {

                    const response =  await getJob(this.id);
                    if (response.data) {
                      this.position = { ...response.data }
                    }

                } catch (e) {
                    // eslint-disable-next-line no-console
                    console.error(e)
                } finally {
                    this.isLoading = false;
                }

            },
            setLabelPosition() {
              const labels = document.querySelectorAll('.polygot');

              labels.forEach((item) =>  {
                const key = item.innerText.replace(/%/g, '');
                item.innerHTML = this.$te('label.'+key) ? this.$t('label.'+key) : ''
              });
            }
        }
    }
</script>

<style lang="scss">
    .position {

      .link-arrow__wrapper {
        margin-top: 50px;

        @media (max-width: $mobile-point) {
          margin-top: 25px;
        }
      }


      h2, h1 {
        flex: 1;

        &:before {
          display: none;
        }
      }


      &-empty {
        width: 100%;
        min-height: 600px;
        background-color: $white;
      }


      .description {
            padding: 75px 0;
            margin-top: 24px;
            border-top: 1px solid rgba(46, 57, 72, 0.1);



            p {
              margin-bottom: 25px;

            }

            h2, h3 {
              font-weight: 500;
              font-size: 22px;
              line-height: 125%;
              margin-bottom: 25px;
            }

            ul {
              padding-left: 0;
              margin-bottom: 75px;

              li {
                margin-bottom: 15px;
                display: flex;
                align-items: center;

                p {
                  margin-bottom: 0;
                }

                strong {
                  font-weight: normal;
                }


                &:last-child {
                  margin-bottom: 0;
                }


                &:before {
                  content: '';
                  min-width: 16px;
                  max-width: 16px;
                  height: 2px;
                  background: $accent;
                  margin-right: 15px;
                  display: flex;
                }
              }
            }
        }

      &-wrapper {
          margin-top: 50px;
          display: flex;
          flex-direction: column;
          position: relative;
        }


      .apply-container {
          display: none;
      }

      &__title {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        flex-wrap: wrap;

        .btn {
          white-space: nowrap;

          @media (max-width: $mobile-point) {
            margin-top: 25px;
            order: 1;
            width: 100%;
          }
        }
      }

      &-meta {
        flex-shrink: 1;
        flex-basis: 100%;
      }
    }
</style>