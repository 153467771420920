export const DEPARTMENTS = {
  'development': 'Development',
  'qa': 'QA',
  'devops': 'DevOps',
  'data': 'Data Analysis',
  'management': 'Management',
  'ui': 'UI/UX',
  'marketing': 'Marketing',
  'backoffice': 'Back Office',
  'business': 'Business Development',
  'support': 'Support'
}

export const DEPARTMENTS_ITEMS = {
  'development': '96',
  'qa': '124',
  'devops': '160',
  'data': '10',
  'management': '107',
  'ui': '34',
  'marketing': '68',
  'backoffice': '137&professional_role=18&professional_role=118&professional_role=88&professional_role=110&professional_role=38&professional_role=69',
  'business': '70',
  'support': '121',
}