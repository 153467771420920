<template>
  <div class="welcome">
    <div class="container">
      <h1 class="welcome__title">
        {{ $t("home.title") }}
      </h1>
      <div class="welcome__desc">
        {{ $t("home.description") }}
      </div>
      <router-link class="btn" :to="{ name: 'home', hash: '#contact'}">
        {{ $t("menu.contacts") }}
      </router-link>
    </div>
    <div class="welcome__img"></div>
  </div>
</template>
<style lang="scss">
  .welcome {
    height: 677px;
    display: flex;
    align-items: center;
    position: relative;
    margin-bottom: 100px;

    @media (max-width: $tablet-point) {
      height: 550px;
    }

    @media (max-width: $mobile-point) {
      flex-direction: column;
      margin: 50px 0;
      height: auto;
    }



    &__title {
      max-width: 550px;
      margin-bottom: 16px;

      @media (max-width: 1200px) {
        max-width: 50%;
      }

      @media (max-width: $mobile-point) {
        max-width: none;
      }
    }

    &__desc {
      max-width: 360px;
      margin-bottom: 24px;

      @media (max-width: $tablet-point) {
        max-width: 50%;
      }

      @media (max-width: $mobile-point) {
        max-width: none;
      }
    }

    .container {
      flex-direction: column;
      align-items: flex-start;
    }

    &__img {
      position: absolute;
      top: 0;
      right: 0;
      width: 50%;
      height: 100%;
      background-image: url("/photos/DSC04045.jpg");
      background-repeat: no-repeat;
      background-position: left;
      background-size: cover;

      @media (max-width: $mobile-point) {
        width: 100%;
        position: relative;
        margin-top: 50px;
        height: 350px;
      }
    }
  }
</style>